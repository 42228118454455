import { Link } from 'gatsby';
import React from 'react';

export const AutoLink = (props) => {
  if (props.href && props.href.startsWith('/')) {
    return <Link to={props.href} {...props} />;
  }

  if (props.href.startsWith('#')) {
    return <a {...props} />;
  } else {
    return <a {...props} target="_blank" rel="noreferrer" />;
  }
};
